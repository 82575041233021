import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

    img {
         max-height: 440px;
    }

    ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}`}



    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Desatero cestování o samotě</Title>
            <Info>
                <Author>Simona</Author>
                <Date>28. 5. 2023</Date>
            </Info>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/tenTips/tenTips1.jpeg" alt=""></StaticImage>
            </StyledFigure>
            <p>Ne každý si to někdy vyzkoušel, ale říká se, že jak to jednou okusíte, už nechcete jinak.</p>
            <p>Je to vážně pravda? To nevím, mě baví cestovat jak s přáteli, tak o samotě. Oboje má své výhody a nevýhody. Máte s kým sdílet zážitky, radosti, pocity i případné problémy, které se na cestě objeví. Oproti tomu když jste sami, musíte se 100 % spolehnout sami na sebe. Jste v neustálém zápřahu pozornosti, opatrnosti a plánování. Má to ale i své pozitivní stránky, snadněji se seznámíte s jinými cestovateli, vytvoříte si krátkodobá i dlouhodobá přátelství, nemusíte hledat kompromis mezi třemi různými názory přátel, se kterými trávíte dovolenou, můžete jít kam chcete, kdy chcete nebo taky nemusíte jít vůbec nikam, když nechcete.</p>
            <p>A protože i já mám za sebou několik svých cestovatelských výletů o samotě, napadlo mě připravit desatero toho, čeho se při cestování o samotě držím já:</p>
                <ol>
                    <li>
                        <p><b>uzavřít si cestovní pojištění</b> (pro některé cesty stačí například to, co máte u své platební karty, někdy je lepší zvážit celoroční pojištění, pokud víte, že cestujete vícekrát do roka nebo čistě individuální na konkrétní cestu)</p>
                    </li>
                    <li>
                        <p><b>vytištěné dokumenty</b> (pokud to jde, vytisknu si předem údaje o cestovním pojištění, letenky i kopii pasu), nikdy nevíte, kdy vám přestane fungovat mobil, na který se v dnešní době všichni tak spoléháme</p>
                    </li>
                    <li>
                        <p><b>vlastní zámek</b>, ten si s sebou vozím vždy, v případě přespávání na hostelech se někdy hodí mít vlastní</p>
                    </li>
                    <li>
                        <p>využívat <b>uzamykacích úložných prostor</b> v hostelech/apartmánech/airbnb, nenosit veškeré dokumenty a finanční obnosy s sebou (při cestování ve více lidech jsem zvyklá dát jednu z kopií pasu příteli/kyni do jeho věcí)</p>
                    </li>
                    <li>
                        <p>na cestování doporučuji mít <b>ledvinku přes rameno</b>, kterou máte neustále na hrudníku pod dozorem, případně existují i menší kapsičky, které můžete umístit pod oblečení</p>
                    </li>
                    <li>
                        <p>snažit se <b>nevyčnívat z davu</b>, nepoutat na sebe zbytečně pozornost, snažit se splynout s prostředím, ve kterém se pohybujeme</p>
                    </li>
                    <li>
                        <p>ideální je i <b>minimalizovat počet zavazadel</b>, hrozí menší riziko ztráty, krádeže nebo jiných nepříjemných situac</p>
                    </li>
                    <li>
                        <p><b>komunikovat s někým</b> o tom, <b>kde se momentálně pohybujete</b>, jaký je váš plán (při cestování o samotě v Mexiku jsem například sdílela kamarádkám polohu, když jsem sama využívala taxi, vím, že kdyby se něco stalo, hned mi nepomohou, ale pro můj pocit při překonání obav a předsudků mi to pomohlo...a navíc jeden zkrátka nikdy neví - sdílet polohu můžete přes Messenger Facebooku, případně Whatsapp)</p>
                    </li>
                    <li>
                        <p><b>respektovat pravidla dané země/kultury</b>, kde se nacházíte, ať už se jedná o nutnost zahalovat určité části těla nebo respektovat pravidla týkající se komunikace mezi ženou a mužem</p>
                    </li>
                    <li>
                        <p>Celkově je dobré <b>řídit se vlastní intuicí</b> a nechodit na místa, která vyvolávají negativní pocity. (mohou to být jen parky nebo temnější uličky po setmění ve městech, určité čtvrti měst, které není bezpečné navštěvovat..) Prostě cestovat s rozvahou a rozumem!</p>
                    </li>
                </ol>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/tenTips/tenTips2.jpeg" alt=""></StaticImage>
                    <StyledFigcaption>Maroko 2018</StyledFigcaption>
                </StyledFigure>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
